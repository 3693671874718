import { useAuth0 } from '@auth0/auth0-react'
import { Country, DeliveryModel, Role, RoleFlag } from 'constants/'
import jwtDecode from 'jwt-decode'
import { useEffect, useMemo, useState } from 'react'
import { indexBy, logFactory } from 'utils'

type RoleFlagsMap = Record<RoleFlag, boolean>

interface UserContext {
  accountId?: string
  allowedCountries: Country[]
  allowedDeliveryModels: DeliveryModel[]
  email?: string
  isAuthenticated: boolean
  name?: string
  permissions: Set<string>
  roleFlags?: RoleFlagsMap
}

const DOMAIN = 'https://hear.com'
const FALLBACK_ALLOWED_COUNTRIES: Country[] = ['US']

interface Auth0User {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [DOMAIN]: Record<string, any>
  email: string
  name: string
}

const log = logFactory('useUser')

// Needed when there are no allowed delivery models
const defaultDeliveryModels = [DeliveryModel.TELEAUDIOLOGY]

const useUser = (): UserContext => {
  const { getAccessTokenSilently, user: auth0User } = useAuth0()
  const [permissions, setPermissions] = useState<Set<string>>(new Set())

  useEffect(() => {
    const asyncFn = async () => {
      try {
        const token = await getAccessTokenSilently()
        const decodedToken: { permissions: string[] } = jwtDecode(token)
        const permissionMap = indexBy(decodedToken.permissions, (permission) => permission)

        setPermissions(new Set(Object.keys(permissionMap)))
      } catch {
        // Do nothing
      }
    }

    asyncFn()
  }, [getAccessTokenSilently, setPermissions])

  const user = auth0User as Auth0User | undefined
  const email = user?.email
  const meta = user?.[DOMAIN]
  const name = user?.name
  const accountId = meta?.user_metadata?.account_id
  const metaAllowedCountries: Country[] = meta?.user_metadata?.allowed_countries ?? []
  const allowedDeliveryModels: DeliveryModel[] = meta?.user_metadata?.allowed_delivery_models ?? defaultDeliveryModels
  const roles = meta?.roles
  const isAuthenticated = Boolean(user)

  const allowedCountries = useMemo(() => {
    if (metaAllowedCountries?.length) {
      return metaAllowedCountries
    }

    return isAuthenticated ? FALLBACK_ALLOWED_COUNTRIES : []
  }, [isAuthenticated, metaAllowedCountries])

  const userContext: UserContext = useMemo(() => {
    const baseContext: UserContext = {
      accountId,
      allowedCountries,
      allowedDeliveryModels,
      email,
      isAuthenticated,
      name,
      permissions,
    }

    /**
     * NOTE: During local development Auth0 will not get back roles
     * ...unless we prompt the user with a consent modal
     * https://github.com/auth0/auth0-react/issues/65#issuecomment-656543646
     * https://auth0.com/docs/authorization/user-consent-and-third-party-applications
     */
    if (!roles) {
      return baseContext
    }

    const rolesSet: Set<Role> = new Set(roles)
    const isAdmin = rolesSet.has(Role.ADMIN)
    const isEmployeeFitter = rolesSet.has(Role.EMPLOYEE_FITTER) || rolesSet.has(Role.INTERNAL_TA_FITTER)
    const isPartnerFitter = rolesSet.has(Role.PARTNER_FITTER) || rolesSet.has(Role.EXTERNAL_TA_FITTER)
    const isFitter = isEmployeeFitter || isPartnerFitter
    const isInternalFitter = isAdmin || isEmployeeFitter
    const isOperations = rolesSet.has(Role.OPS) || rolesSet.has(Role.OPERATIONS)

    const roleFlags = {
      isAdmin,
      isEmployeeFitter,
      isFitter,
      isInternalFitter,
      isOperations,
      isPartnerFitter,
    }

    return {
      ...baseContext,
      roleFlags,
    }
  }, [accountId, allowedCountries, email, isAuthenticated, name, permissions, roles])

  useEffect(() => {
    if (!isAuthenticated) return

    log(`👤 Auth0 user`, user)
  }, [isAuthenticated])

  return userContext
}

export { useUser }
export type { RoleFlagsMap }
