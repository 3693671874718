enum Role {
  ADMIN = 'Admin',
  /**
   * @deprecated Please use INTERNAL_TA_FITTER
   */
  EMPLOYEE_FITTER = 'EmployeeFitter',
  EXTERNAL_TA_FITTER = 'External TA Fitter',
  INTERNAL_TA_FITTER = 'Internal TA Fitter',
  /**
   * @deprecated Please use EXTERNAL_TA_FITTER
   */
  PARTNER_FITTER = 'PartnerFitter',
  /**
   * @deprecated Please use OPERATIONS
   */
  OPS = 'Ops',
  OPERATIONS = 'Operations',
}

enum RoleFlag {
  isAdmin = 'isAdmin',
  isEmployeeFitter = 'isEmployeeFitter',
  isFitter = 'isFitter',
  isInternalFitter = 'isInternalFitter',
  isPartnerFitter = 'isPartnerFitter',
  isOperations = 'isOperations',
}

export { Role, RoleFlag }
